// src/services/guidingMachineService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';
import { v4 as uuidv4 } from 'uuid';

const API_BASE_URL = '/api/guidingmachine';

const handleError = (error) => {
  if (error.response && error.response.data) {
    throw new Error(error.response.data.message || 'Operation failed');
  }
  throw new Error('Operation failed');
};

export const fetchUserBookings = async (userId, apiKey, organizationId, jwtToken) => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}`);
  return response.data;
};

export const fetchUserBookingsbyUserId = async (userId, apiKey, organizationId, jwtToken) => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}`);
  return response.data;
};


export const fetchBookingsPerDate = async (userId, date, apiKey, organizationId, jwtToken) => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}?userId=${userId}&date=${date}`);
  return response.data;
};

export const bookingStatistics = async (userId, date, apiKey, organizationId, jwtToken, statisticsType = 'month') => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/statistics?userId=${userId}&date=${date}&statisticsType=${statisticsType}`);
  return response.data;
};

export const fetchSpecificOwnerBookings = async (userId, date, ownerId, apiKey, organizationId, jwtToken) => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const response = await axiosInstance.get(`${API_BASE_URL}/statistics?userId=${userId}&date=${date}&ownerId=${ownerId}`);
  return response.data;
};

export const createBooking = async (startDateTime, endDateTime, ownerId, horseId, comment, whom, deviceId, apiKey, organizationId, jwtToken) => {
  const bookingData = {
    id: uuidv4(),  // Generate UUID and add to the booking data
    horse_id: horseId,
    owner_id: ownerId,
    device_id: deviceId,
    start_time: startDateTime.toISOString(),
    end_time: endDateTime.toISOString(),
    description: comment,
    whom: whom,
  };

  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  try {
    const response = await axiosInstance.post(API_BASE_URL, bookingData);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const markBookingsAsPaid = async (bookingIds, apiKey, organizationId, jwtToken) => {
  const payload = {
    bookingIds,  // The list of booking IDs to mark as paid
  };

  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/markaspaid`, payload);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const updateBookingPayStatus = async (bookingIds, payed, apiKey, organizationId, jwtToken) => {
  const payload = {
    bookingIds,  // The list of booking IDs to update
    payed       // Boolean indicating payment status
  };

  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  try {
    const response = await axiosInstance.post(`${API_BASE_URL}/markaspaid`, payload); // Endpoint remains the same
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteBooking = async (bookingId, ownerId, apiKey, organizationId, jwtToken) => {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  try {
    const response = await axiosInstance.delete(`${API_BASE_URL}?Id=${bookingId}&ownerId=${ownerId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};
