// path: frontend/equistab/src/services/WorkingTimeEventService.js
import axiosInstance, { setApiKeyHeader, setOrganizationIdHeader, setAuthHeader } from './apiConfig/axiosConfig.js';

const API_BASE_URL = '/api/workingtimeevents';

export async function fetchWorkingTimeEventsByEmployee(
  apiKey, organizationId, jwtToken, userId, employeeId, startDate, endDate
) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  let url = `${API_BASE_URL}?employeeId=${employeeId}`;
  if (startDate) {
    url += `&startDate=${encodeURIComponent(startDate)}`;
  }
  if (endDate) {
    url += `&endDate=${encodeURIComponent(endDate)}`;
  }

  const response = await axiosInstance.get(url);
  return response.data;
}


export async function saveWorkingTimeEvent(eventData, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}`;
  const response = await axiosInstance.post(url, eventData);
  return response.data;
}

export async function updateWorkingTimeEvent(eventId, eventData, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?eventId=${eventId}`;
  const response = await axiosInstance.put(url, eventData);
  return response.data;
}

export async function deleteWorkingTimeEvent(eventId, apiKey, organizationId, jwtToken, userId) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `${API_BASE_URL}?eventId=${eventId}`;
  const response = await axiosInstance.delete(url);
  return response.data;
}

export async function createWorkingTimeEventPDF(employeeId, organizationId, month, apiKey, jwtToken) {
  setApiKeyHeader(apiKey);
  setOrganizationIdHeader(organizationId);
  setAuthHeader(jwtToken);

  const url = `/api/workingtimeevents/pdf?employeeId=${employeeId}&month=${month}`;
  const response = await axiosInstance.get(url, { responseType: 'blob' });
  return response.data;
}