// src/components/UserDetailsYear.js

import React, { useEffect, useState } from 'react';
import {
    TextField, IconButton, Card, CardContent, Checkbox, Table, TableBody, TableCell,
    TableContainer, TableHead, TableRow, Paper, Dialog, DialogTitle, DialogContent,
    DialogActions, Button, Box, Typography, LinearProgress, Snackbar, Alert
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'; // Icon for marking as unpaid
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'; // Filled icon for selected unpaid
import { bookingStatistics, updateBookingPayStatus } from '../../services/guidingMachineService';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { GiHorseHead } from 'react-icons/gi';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import { useDateLocale } from '../../hooks/useDateLocale';
import { fetchPricingByReference } from '../../services/PricingService';

const UserDetailsYear = () => {
    const [selectedDate, setSelectedDate] = useState(new Date(new Date().getFullYear(), 0, 1));
    const [selectedUser, setSelectedUser] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const { t, i18n } = useTranslation();
    const { apiKey, organizationId, userId, token } = useAuth();
    const [selectedToMarkAsPaid, setSelectedToMarkAsPaid] = useState([]); // For unpaid bookings
    const [selectedToMarkAsUnpaid, setSelectedToMarkAsUnpaid] = useState([]); // For paid bookings
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [fetchTrigger, setFetchTrigger] = useState(0);
    const jwtToken = token;
    const [machineMap, setMachineMap] = useState({});
    const MachineIcon = FitnessCenterIcon;
    const HorseIcon = GiHorseHead;
    const dateLocale = useDateLocale();
    const [deviceTotals, setDeviceTotals] = useState({});
    const [bookingsPerHorse, setBookingsPerHorse] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const year = selectedDate.getFullYear();
                const response = await bookingStatistics(userId, year, apiKey, organizationId, jwtToken, 'year');
                const machineIds = [...new Set(response.bookingCounts.flatMap(user => user.bookings.map(booking => booking.machine_id)))];
                const pricingMap = await fetchPricingForMachines(machineIds, apiKey, organizationId, jwtToken);
                const matchedBookings = matchPricingWithBookings(response.bookingCounts, pricingMap);
                setMachineMap(organizeBookingsByMachine(matchedBookings));
                setData(matchedBookings);
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
            setLoading(false);
        };

        fetchData();
    }, [selectedDate, fetchTrigger]);

    const fetchPricingForMachines = async (machineIds, apiKey, organizationId, jwtToken) => {
        const pricingMap = new Map();
        const pricingPromises = machineIds.map(async (machineId) => {
            const pricing = await fetchPricingByReference(apiKey, organizationId, jwtToken, machineId);
            pricingMap.set(machineId, pricing);
        });
        await Promise.all(pricingPromises);
        return pricingMap;
    };

    const matchPricingWithBookings = (users, pricingMap) => {
        return users.map((user) => {
            const matchedBookings = user.bookings.map((booking) => {
                const pricing = pricingMap.get(booking.machine_id);
                const numericPrice = Number(pricing?.bruttoPrice);

                if (isNaN(numericPrice)) {
                    console.error('Invalid price:', pricing?.bruttoPrice);
                    return { ...booking, pricing: 0 };
                }

                return { ...booking, pricing: numericPrice };
            });
            return { ...user, bookings: matchedBookings };
        });
    };

    const calculateTotalsPerDevice = () => {
        const totals = {};

        selectedUser?.bookings.forEach((booking) => {
            if (selectedToMarkAsPaid.includes(booking.bookingId) && booking.payed === "False") {
                if (!totals[booking.machine_id]) {
                    totals[booking.machine_id] = 0;
                }
                totals[booking.machine_id] += Number(booking.pricing || 0);
            }
            if (selectedToMarkAsUnpaid.includes(booking.bookingId) && booking.payed === "True") {
                if (!totals[booking.machine_id]) {
                    totals[booking.machine_id] = 0;
                }
                totals[booking.machine_id] += Number(booking.pricing || 0);
            }
        });

        return totals;
    };

    useEffect(() => {
        const deviceTotals = calculateTotalsPerDevice();
        setDeviceTotals(deviceTotals);
    }, [selectedToMarkAsPaid, selectedToMarkAsUnpaid, selectedUser?.bookings]);

    const incrementYear = () => {
        const newDate = new Date(selectedDate);
        newDate.setFullYear(newDate.getFullYear() + 1);
        setSelectedDate(newDate);
    };

    const decrementYear = () => {
        const newDate = new Date(selectedDate);
        newDate.setFullYear(newDate.getFullYear() - 1);
        setSelectedDate(newDate);
    };

    const handleOpenDialog = (user) => {
        setSelectedUser(user);
        const initialSelectedToMarkAsPaid = user.bookings.filter(booking => booking.payed === "False").map(booking => booking.bookingId);
        setSelectedToMarkAsPaid(initialSelectedToMarkAsPaid);
        setSelectedToMarkAsUnpaid([]); // Reset unpaid selections
        setBookingsPerHorse({});
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedToMarkAsPaid([]);
        setSelectedToMarkAsUnpaid([]);
    };

    const callYourServiceWithSelectedBookings = async () => {
        try {
            // Mark as paid
            if (selectedToMarkAsPaid.length > 0) {
                await updateBookingPayStatus(selectedToMarkAsPaid, true, apiKey, organizationId, jwtToken);
            }
            // Mark as unpaid
            if (selectedToMarkAsUnpaid.length > 0) {
                await updateBookingPayStatus(selectedToMarkAsUnpaid, false, apiKey, organizationId, jwtToken);
            }
            handleCloseDialog();
            setSnackbarOpen(true);
            setFetchTrigger(fetchTrigger + 1);
        } catch (error) {
            console.log('Failed to update selected bookings', error);
        }
    };

    const toggleSelectedToMarkAsPaid = (bookingId) => {
        setSelectedToMarkAsPaid((prevSelected) => {
            if (prevSelected.includes(bookingId)) {
                return prevSelected.filter((id) => id !== bookingId);
            } else {
                return [...prevSelected, bookingId];
            }
        });
    };

    const toggleSelectedToMarkAsUnpaid = (bookingId) => {
        setSelectedToMarkAsUnpaid((prevSelected) => {
            if (prevSelected.includes(bookingId)) {
                return prevSelected.filter((id) => id !== bookingId);
            } else {
                return [...prevSelected, bookingId];
            }
        });
    };

    const formatPrice = (price) => {
        const numericPrice = Number(price);
        if (isNaN(numericPrice)) {
            console.error('Invalid price:', price);
            return 'N/A';
        }
        return `${numericPrice.toFixed(2)} ${i18n.language === 'en' ? '$' : '€'}`;
    };

    const organizeBookingsByMachine = (bookingCounts) => {
        const machineMap = {};
        bookingCounts.forEach(user => {
            user.machines.forEach(machine => {
                if (!machineMap[machine.id]) {
                    machineMap[machine.id] = {
                        name: machine.name,
                        paidCount: 0,
                        unpaidCount: 0,
                        bookings: []
                    };
                }
                machineMap[machine.id].bookings.push(...user.bookings.filter(booking => booking.machine_id === machine.id));
                machineMap[machine.id].paidCount += machine.paidCount;
                machineMap[machine.id].unpaidCount += machine.count - machine.paidCount;
            });
        });
        return machineMap;
    };

    const calculateTotalPaidForMachine = (userBookings, machineId) => {
        return userBookings
            .filter(booking => booking.machine_id === machineId && booking.payed === "True")
            .reduce((total, booking) => total + (booking.pricing || 0), 0);
    };

    const calculateTotalUnpaidForMachine = (userBookings, machineId) => {
        return userBookings
            .filter(booking => booking.machine_id === machineId && booking.payed === "False")
            .reduce((total, booking) => total + (booking.pricing || 0), 0);
    };

    const handleShowMore = (horseId) => {
        setBookingsPerHorse(prevState => ({
            ...prevState,
            [horseId]: (prevState[horseId] || 15) + 15
        }));
    };

    if (loading) {
        return (
            <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" minHeight="100vh">
                <Typography variant="h6">{t('GuidingMachinePage.UserDetails.waitingText')}</Typography>
                <LinearProgress />
            </Box>
        );
    }

    return (
        <div>
            <h2>{t('GuidingMachinePage.UserDetails.userInformation')}</h2>
            <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
                <Box display="flex" alignItems="center" justifyContent="space-between" flexWrap="nowrap" mb={2}>
                    <IconButton onClick={decrementYear}>
                        <ArrowBackIcon />
                    </IconButton>
                    <Typography>{selectedDate.getFullYear()}</Typography>
                    <IconButton onClick={incrementYear}>
                        <ArrowForwardIcon />
                    </IconButton>
                </Box>
            </LocalizationProvider>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t('GuidingMachinePage.UserDetails.name')}</TableCell>
                            <TableCell>{t('GuidingMachinePage.UserDetails.totalBookings')}</TableCell>
                            <TableCell>{t('GuidingMachinePage.UserDetails.paidBookings')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((user) => (
                            <TableRow key={user.name} onClick={() => handleOpenDialog(user)} style={{ cursor: 'pointer' }}>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {user.count}
                                    </Box>
                                </TableCell>
                                <TableCell>
                                    <Box display="flex" alignItems="center">
                                        {user.paidCount}
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog open={openDialog} onClose={handleCloseDialog} maxWidth="md" fullWidth>
                <DialogTitle>{selectedUser?.name + ' ' + t('GuidingMachinePage.UserDetails.sHorses')}</DialogTitle>
                <DialogContent>
                    {selectedUser?.machines.map((machine) => {
                        const machineBooking = selectedUser?.bookings.filter(booking => booking.machine_id === machine.id);
                        const machineUnpaid = machine.count - machine.paidCount;
                        return (
                            <Card elevation={3} key={machine.id} style={{ marginBottom: '20px' }}>
                                <CardContent>
                                    <Typography variant="h5" alignItems="center">
                                        <MachineIcon /> {`${t('GuidingMachinePage.UserDetails.deviceName')}: ${machine.name}`}
                                    </Typography>
                                    <Box display="flex" alignItems="center" justifyContent="space-around" mt={2} mb={4}>
                                        <Box display="flex" alignItems="center">
                                            {`${t('GuidingMachinePage.UserDetails.Paid')}: ${machine.paidCount}`}
                                        </Box>
                                        <Box display="flex" alignItems="center">
                                            {`${t('GuidingMachinePage.UserDetails.Unpaid')}: ${machineUnpaid}`}
                                        </Box>
                                    </Box>
                                    {selectedUser?.horses
                                        .filter(horse => {
                                            const relatedBookings = selectedUser.bookings.filter(booking => booking.horseId === horse.id && booking.machine_id === machine.id);
                                            return relatedBookings.length > 0;
                                        })
                                        .map((horse) => {
                                            const horseBookings = selectedUser.bookings.filter((booking) => booking.horseId === horse.id && booking.machine_id === machine.id);
                                            const displayBookings = horseBookings.slice(0, bookingsPerHorse[horse.id] || 15);

                                            return (
                                                <Card elevation={2} key={horse.id} style={{ margin: '10px 0' }}>
                                                    <CardContent>
                                                        <Box key={horse.id}>
                                                            <Typography variant="h6">
                                                                <HorseIcon /> {horse.name}
                                                            </Typography>
                                                            {displayBookings.length > 0 ? (
                                                                <Box>
                                                                    {displayBookings.map((booking, index) => (
                                                                        <Box
                                                                            display="flex"
                                                                            alignItems="center"
                                                                            key={index}
                                                                        >
                                                                            {
                                                                                booking.payed === "True" ? (
                                                                                    <>
                                                                                        <AttachMoneyIcon color="primary" />
                                                                                        <Typography style={{
                                                                                            color: "green",
                                                                                            marginLeft: '8px'
                                                                                        }}>
                                                                                            {t('GuidingMachinePage.UserDetails.booking')}: {new Date(booking.startTime).toLocaleString()}
                                                                                        </Typography>
                                                                                        <IconButton
                                                                                            color={selectedToMarkAsUnpaid.includes(booking.bookingId) ? "secondary" : "default"}
                                                                                            onClick={() => toggleSelectedToMarkAsUnpaid(booking.bookingId)}
                                                                                            size="small"
                                                                                            aria-label="Toggle Unmark as Unpaid"
                                                                                        >
                                                                                            {selectedToMarkAsUnpaid.includes(booking.bookingId) ? <RemoveCircleIcon /> : <RemoveCircleOutlineIcon />}
                                                                                        </IconButton>
                                                                                    </>
                                                                                ) : (
                                                                                    <>
                                                                                        <Checkbox
                                                                                            checked={selectedToMarkAsPaid.includes(booking.bookingId)}
                                                                                            onChange={() => toggleSelectedToMarkAsPaid(booking.bookingId)}
                                                                                        />
                                                                                        <Typography style={{
                                                                                            color: "black"
                                                                                        }}>
                                                                                            {t('GuidingMachinePage.UserDetails.booking')}: {new Date(booking.startTime).toLocaleString()}
                                                                                        </Typography>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </Box>
                                                                    ))}
                                                                    {horseBookings.length > displayBookings.length && (
                                                                        <Button onClick={() => handleShowMore(horse.id)}>
                                                                            {t('GuidingMachinePage.UserDetails.showMore')}
                                                                        </Button>
                                                                    )}
                                                                </Box>
                                                            ) : (
                                                                <Typography>{t('GuidingMachinePage.UserDetails.NoBookings')}</Typography>
                                                            )}
                                                        </Box>
                                                    </CardContent>
                                                </Card>
                                            );
                                        })}
                                    <Box display="flex" justifyContent="space-between" flexDirection='column'>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.PaidSum')}{formatPrice(calculateTotalPaidForMachine(selectedUser.bookings, machine.id))}
                                        </Typography>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.UnPaidSum')}{formatPrice(calculateTotalUnpaidForMachine(selectedUser.bookings, machine.id))}
                                        </Typography>
                                        <Typography>
                                            {t('GuidingMachinePage.UserDetails.selectedSum')}{formatPrice(deviceTotals[machine.id] || 0)}
                                        </Typography>
                                    </Box>
                                </CardContent>
                            </Card>
                        );
                    })}
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={callYourServiceWithSelectedBookings} // Apply both actions
                        disabled={selectedToMarkAsPaid.length === 0 && selectedToMarkAsUnpaid.length === 0}
                    >
                        {t('GuidingMachinePage.UserDetails.SendSelection')}
                    </Button>
                    <Button onClick={handleCloseDialog}>{t('GuidingMachinePage.UserDetails.close')}</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success">
                    {t('GuidingMachinePage.UserDetails.notification')}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default UserDetailsYear;
