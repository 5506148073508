import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { Button, Typography, Container, Box } from '@mui/material';

const ErrorPage = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();

    // Logout function to clear Azure AD B2C session
    const logoutFromAAD = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: '/auth', // Redirect back to the login page after logout
        });
    };

    return (
        <Container maxWidth="sm" style={{ textAlign: 'center', marginTop: '10%' }}>
            <Typography variant="h4" gutterBottom>
                Authentication Error
            </Typography>
            <Typography variant="body1" style={{ marginBottom: '20px' }}>
                We couldn't find your account in the system. Please try logging in again.
            </Typography>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    style={{ margin: '10px' }}
                    onClick={logoutFromAAD}
                >
                    Retry Login
                </Button>
            </Box>
        </Container>
    );
};

export default ErrorPage;
