import React, { useState, useEffect } from 'react';
import {
  Paper,
  Button,
  Typography,
  Modal,
  TextField,
  Grid,
  MenuItem,
  Fab,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext';
import { fetchEmployees } from '../../services/EmployeeService';
import {
  fetchWorkingTimeEventsByEmployee,
  saveWorkingTimeEvent,
  updateWorkingTimeEvent,
  deleteWorkingTimeEvent,
  createWorkingTimeEventPDF,
} from '../../services/WorkingTimeEventService';
import SnackbarAlert from '../../components/SnackbarAlert';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers';
import { useDateLocale } from '../../hooks/useDateLocale';
import { saveAs } from 'file-saver';

moment.updateLocale('en', {
  week: {
    dow: 1, // Monday is the first day of the week
  },
});

const localizer = momentLocalizer(moment);

const WorkingTimeDetailPage = () => {
  const { t } = useTranslation();
  const { token, apiKey, organizationId, userId } = useAuth();
  const navigate = useNavigate();
  const { id } = useParams(); // <-- Grab the :id param here
  const dateLocale = useDateLocale();

  // If we have an ID and it's not "new", we assume it's an existing employee
  const [employeeId, setEmployeeId] = useState(id !== 'new' ? id : '');
  const [events, setEvents] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [snackbar, setSnackbar] = useState({ open: false, message: '', severity: '' });
  const [modalOpen, setModalOpen] = useState(false);
  const [entryData, setEntryData] = useState({});
  const [selectedSlot, setSelectedSlot] = useState(null);

  // ** Default the calendar to 'month' view **
  const [currentDate, setCurrentDate] = useState(new Date());
  const [currentView, setCurrentView] = useState('month');

  // For event editing
  const [isEditing, setIsEditing] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);

  // Entry types
  const entryTypes = [
    { value: 'working', label: t('workingTimeDetail.workingHours') },
    { value: 'break', label: t('workingTimeDetail.break') },
    { value: 'vacation', label: t('workingTimeDetail.vacation') },
  ];

  // Define calendar formats (24-hour time, etc.)
  const formats = {
    timeGutterFormat: 'HH:mm',
    eventTimeRangeFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'HH:mm', culture) + ' – ' + localizer.format(end, 'HH:mm', culture),
    agendaTimeFormat: 'HH:mm',
    dayRangeHeaderFormat: ({ start, end }, culture, localizer) =>
      localizer.format(start, 'MMM DD', culture) + ' – ' + localizer.format(end, 'MMM DD', culture),
    dayHeaderFormat: 'dddd, MMMM DD, YYYY',
  };

  // Fetch the list of employees
  useEffect(() => {
    const fetchEmployeesData = async () => {
      try {
        const data = await fetchEmployees(apiKey, organizationId, token, userId);
        setEmployees(data);
      } catch (error) {
        console.error('Error fetching employees:', error);
        setSnackbar({ open: true, message: t('workingTimeDetail.fetchEmployeesError'), severity: 'error' });
      }
    };
    fetchEmployeesData();
  }, [apiKey, organizationId, token, userId, t]);

  // Whenever employeeId, currentView, or currentDate changes, load events
  useEffect(() => {
    console.log('Fetching events for:', employeeId, currentDate, currentView);
    if (employeeId && employeeId !== 'new') {
      loadEvents(employeeId, currentDate, currentView);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employeeId, currentDate, currentView]);


  // Handle PDF generation
  const handleGeneratePDF = async () => {
    if (!employeeId) {
      setSnackbar({
        open: true,
        message: t('workingTimeDetail.noEmployeeSelected'),
        severity: 'error',
      });
      return;
    }

    const currentMonth = moment().format('YYYY-MM'); // Current month in YYYY-MM format
    try {
      const pdfData = await createWorkingTimeEventPDF(employeeId, organizationId, currentMonth, apiKey, token);
      const blob = new Blob([pdfData], { type: 'application/pdf' });
      saveAs(blob, `WorkingTimeReport_${currentMonth}.pdf`); // Trigger file download
      setSnackbar({
        open: true,
        message: t('workingTimeDetail.pdfGenerated'),
        severity: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: t('workingTimeDetail.pdfGenerationError'),
        severity: 'error',
      });
    }
  };

  // Load events for the chosen employee within the current view range
  const loadEvents = async (empId, viewDate, viewName) => {
    let startDate, endDate;

    if (viewName === 'month') {
      startDate = moment(viewDate).startOf('month').toISOString();
      endDate = moment(viewDate).endOf('month').toISOString();
    } else if (viewName === 'week') {
      startDate = moment(viewDate).startOf('week').toISOString();
      endDate = moment(viewDate).endOf('week').toISOString();
    }

    try {
      const fetchedEvents = await fetchWorkingTimeEventsByEmployee(
        apiKey,
        organizationId,
        token,
        userId,
        empId,
        startDate,
        endDate
      );
      setEvents(
        fetchedEvents.map((evt) => ({
          ...evt,
          start: new Date(evt.start),
          end: new Date(evt.end),
        }))
      );
    } catch (error) {
      console.error('Error fetching events:', error);
      setSnackbar({ open: true, message: t('workingTimeDetail.fetchEventsError'), severity: 'error' });
    }
  };

  // Employee selection handler
  const handleEmployeeChange = async (e) => {
    setEmployeeId(e.target.value);
    setEvents([]);
  };

  // Handle slot selection (when clicking on the calendar)
  const handleSelectSlot = (slotInfo) => {
    setSelectedSlot(slotInfo);
    setIsEditing(false);
    // Sort the slots if multiple days
    const sortedSlots = [...slotInfo.slots].sort((a, b) => moment(a).diff(moment(b)));

    // For month view, we might want to default times, e.g., 09:00 - 17:00
    const defaultStartTime = currentView === 'month'
      ? '09:00'
      : moment(slotInfo.start).format('HH:mm');
    const defaultEndTime = currentView === 'month'
      ? '17:00'
      : moment(slotInfo.end).format('HH:mm');

    setEntryData({
      startTime: defaultStartTime,
      endTime: defaultEndTime,
      type: 'working',
      startDate: moment(sortedSlots[0]).startOf('day').toDate(),
      endDate: moment(sortedSlots[sortedSlots.length - 1]).endOf('day').toDate(),
      createSingleEvent: slotInfo.slots.length > 1,
    });
  };

  // Handle event selection (edit existing event)
  const handleSelectEvent = (event) => {
    setIsEditing(true);
    setEventToEdit(event);
    setSelectedSlot(null);
    setEntryData({
      startTime: moment(event.start).format('HH:mm'),
      endTime: moment(event.end).format('HH:mm'),
      type: event.type,
      startDate: moment(event.start).toDate(),
      endDate: moment(event.end).toDate(),
      createSingleEvent: false, // Not relevant for editing an existing event
    });
    setModalOpen(true);
  };

  // Close modal handler
  const handleModalClose = () => {
    setModalOpen(false);
    setSelectedSlot(null);
    setIsEditing(false);
    setEventToEdit(null);
  };

  // Save or update event
  const handleSaveEntry = async () => {
    if (!employeeId || employeeId === 'new') {
      setSnackbar({ open: true, message: t('workingTimeDetail.noEmployeeSelected'), severity: 'error' });
      return;
    }

    const isVacation = entryData.type === 'vacation';
    let eventData = {};

    // ---- EDIT EXISTING EVENT ----
    if (isEditing && eventToEdit) {
      eventData = {
        ...eventToEdit,
        start: isVacation
          ? moment(entryData.startDate).startOf('day').toDate()
          : moment(entryData.startDate)
            .set({
              hour: moment(entryData.startTime, 'HH:mm').hour(),
              minute: moment(entryData.startTime, 'HH:mm').minute(),
            })
            .toDate(),
        end: isVacation
          ? moment(entryData.endDate).endOf('day').toDate()
          : moment(entryData.endDate)
            .set({
              hour: moment(entryData.endTime, 'HH:mm').hour(),
              minute: moment(entryData.endTime, 'HH:mm').minute(),
            })
            .toDate(),
        type: entryData.type,
        allDay: isVacation,
        title: isVacation
          ? 'Vacation'
          : `${entryData.startTime} - ${entryData.endTime}`,
      };

      try {
        const updatedEvent = await updateWorkingTimeEvent(
          eventToEdit.id,
          eventData,
          apiKey,
          organizationId,
          token,
          userId
        );
        // Update events in local state
        setEvents((prev) =>
          prev.map((evt) =>
            evt.id === updatedEvent.id
              ? {
                ...updatedEvent,
                start: new Date(updatedEvent.start),
                end: new Date(updatedEvent.end),
              }
              : evt
          )
        );
        setSnackbar({ open: true, message: t('workingTimeDetail.updateSuccess'), severity: 'success' });
      } catch (error) {
        console.error('Error updating event:', error);
        setSnackbar({ open: true, message: t('workingTimeDetail.updateError'), severity: 'error' });
      }

      setModalOpen(false);
      setIsEditing(false);
      setEventToEdit(null);
      return;
    }

    // ---- CREATE NEW EVENT(S) ----
    if (selectedSlot) {
      const { slots } = selectedSlot;
      const sortedSlots = [...slots].sort((a, b) => moment(a).diff(moment(b)));
      const startOfFirstDay = moment(sortedSlots[0]).startOf('day');
      const endOfLastDay = moment(sortedSlots[sortedSlots.length - 1]).endOf('day');

      // If multiple days selected
      if (sortedSlots.length > 1) {
        if (!entryData.createSingleEvent) {
          // Create a single multi-day event
          eventData = {
            employeeId,
            start: isVacation
              ? startOfFirstDay.toDate()
              : moment(startOfFirstDay)
                .set({
                  hour: moment(entryData.startTime, 'HH:mm').hour(),
                  minute: moment(entryData.startTime, 'HH:mm').minute(),
                })
                .toDate(),
            end: isVacation
              ? endOfLastDay.toDate()
              : moment(endOfLastDay)
                .set({
                  hour: moment(entryData.endTime, 'HH:mm').hour(),
                  minute: moment(entryData.endTime, 'HH:mm').minute(),
                })
                .toDate(),
            type: entryData.type,
            allDay: isVacation,
            title: isVacation
              ? 'Vacation'
              : `${entryData.startTime} - ${entryData.endTime}`,
          };

          try {
            const savedEvent = await saveWorkingTimeEvent(eventData, apiKey, organizationId, token, userId);
            setEvents((prev) => [
              ...prev,
              {
                ...savedEvent,
                start: new Date(savedEvent.start),
                end: new Date(savedEvent.end),
              },
            ]);
            setSnackbar({ open: true, message: t('workingTimeDetail.saveSuccess'), severity: 'success' });
          } catch (error) {
            console.error('Error saving event:', error);
            setSnackbar({ open: true, message: t('workingTimeDetail.saveError'), severity: 'error' });
          }
        } else {
          // Create separate single-day events for each selected day
          const newEvents = sortedSlots.map((slotDate) => {
            const slotMoment = moment(slotDate).startOf('day');
            return {
              employeeId,
              start: isVacation
                ? slotMoment.toDate()
                : slotMoment.set({
                  hour: moment(entryData.startTime, 'HH:mm').hour(),
                  minute: moment(entryData.startTime, 'HH:mm').minute(),
                }).toDate(),
              end: isVacation
                ? moment(slotDate).endOf('day').toDate()
                : slotMoment.set({
                  hour: moment(entryData.endTime, 'HH:mm').hour(),
                  minute: moment(entryData.endTime, 'HH:mm').minute(),
                }).toDate(),
              type: entryData.type,
              allDay: isVacation,
              title: isVacation
                ? 'Vacation'
                : `${entryData.startTime} - ${entryData.endTime}`,
            };
          });

          try {
            const savedEvents = await Promise.all(
              newEvents.map((evt) => saveWorkingTimeEvent(evt, apiKey, organizationId, token, userId))
            );
            setEvents((prev) => [
              ...prev,
              ...savedEvents.map((evt) => ({
                ...evt,
                start: new Date(evt.start),
                end: new Date(evt.end),
              })),
            ]);
            setSnackbar({ open: true, message: t('workingTimeDetail.saveSuccess'), severity: 'success' });
          } catch (error) {
            console.error('Error saving events:', error);
            setSnackbar({ open: true, message: t('workingTimeDetail.saveError'), severity: 'error' });
          }
        }
      } else {
        // Single day selection
        eventData = {
          employeeId,
          start: isVacation
            ? startOfFirstDay.toDate()
            : moment(entryData.startDate).set({
              hour: moment(entryData.startTime, 'HH:mm').hour(),
              minute: moment(entryData.startTime, 'HH:mm').minute(),
            }).toDate(),
          end: isVacation
            ? moment(entryData.endDate).endOf('day').toDate()
            : moment(entryData.endDate).set({
              hour: moment(entryData.endTime, 'HH:mm').hour(),
              minute: moment(entryData.endTime, 'HH:mm').minute(),
            }).toDate(),
          type: entryData.type,
          allDay: isVacation,
          title: isVacation ? 'Vacation' : `${entryData.startTime} - ${entryData.endTime}`,
        };

        try {
          const savedEvent = await saveWorkingTimeEvent(eventData, apiKey, organizationId, token, userId);
          setEvents((prev) => [
            ...prev,
            {
              ...savedEvent,
              start: new Date(savedEvent.start),
              end: new Date(savedEvent.end),
            },
          ]);
          setSnackbar({ open: true, message: t('workingTimeDetail.saveSuccess'), severity: 'success' });
        } catch (error) {
          console.error('Error saving event:', error);
          setSnackbar({ open: true, message: t('workingTimeDetail.saveError'), severity: 'error' });
        }
      }

      setModalOpen(false);
      setSelectedSlot(null);
    }
  };

  // Delete an existing event
  const handleDeleteEvent = async () => {
    if (!eventToEdit) return;
    try {
      await deleteWorkingTimeEvent(eventToEdit.id, apiKey, organizationId, token, userId);
      setEvents((prev) => prev.filter((evt) => evt.id !== eventToEdit.id));
      setSnackbar({ open: true, message: t('workingTimeDetail.deleteSuccess'), severity: 'success' });
    } catch (error) {
      console.error('Error deleting event:', error);
      setSnackbar({ open: true, message: t('workingTimeDetail.deleteError'), severity: 'error' });
    }

    setModalOpen(false);
    setEventToEdit(null);
    setIsEditing(false);
  };

  // Event styling by type
  const eventStyleGetter = (event) => {
    let style = {};
    switch (event.type) {
      case 'working':
        style = { backgroundColor: '#64b5f6', color: 'white', border: '1px solid #1e88e5' };
        break;
      case 'vacation':
        style = { backgroundColor: '#ffb74d', color: 'white', border: '1px solid #f57c00' };
        break;
      case 'break':
        style = { backgroundColor: '#aed581', color: 'white', border: '1px solid #7cb342' };
        break;
      default:
        style = { backgroundColor: '#64b5f6', color: 'white', border: '1px solid #1e88e5' };
    }
    return { style };
  };

  // Sample dayStyleGetter if you need to highlight selected days in month view
  const dayStyleGetter = (date) => {
    if (currentView === 'month') {
      if (
        selectedSlot &&
        selectedSlot.slots &&
        selectedSlot.slots.some((slotDate) => moment(slotDate).isSame(date, 'day'))
      ) {
        return {
          style: {
            backgroundColor: '#a5d6a7',
            border: '1px solid #388e3c',
          },
        };
      }
    }
    return {};
  };

  // Sample slotPropGetter if you need to highlight selected time slots in week view
  const slotPropGetter = (date) => {
    if (currentView !== 'month') {
      if (
        selectedSlot &&
        moment(date).isBetween(selectedSlot.start, selectedSlot.end, null, '[)')
      ) {
        return {
          style: {
            backgroundColor: '#a5d6a7',
            border: '1px solid #388e3c',
          },
        };
      }
    }
    return {};
  };

  // Example of a side panel summary (optional)
  const renderSidePanel = () => {
    const startOfPeriod =
      currentView === 'month'
        ? moment(currentDate).startOf('month')
        : moment(currentDate).startOf('week');
    const endOfPeriod =
      currentView === 'month'
        ? moment(currentDate).endOf('month')
        : moment(currentDate).endOf('week');

    const periodEvents = events.filter((e) =>
      moment(e.start).isBetween(startOfPeriod, endOfPeriod, null, '[)')
    );

    let totalWorkedHours = 0;
    let totalBreakHours = 0;
    let totalVacationDays = 0;

    periodEvents.forEach((event) => {
      const duration = moment(event.end).diff(moment(event.start), 'hours', true);
      if (event.type === 'working') {
        totalWorkedHours += duration;
      } else if (event.type === 'break') {
        totalBreakHours += duration;
      } else if (event.type === 'vacation') {
        totalVacationDays += 1;
      }
    });

    return (
      <div style={{ marginBottom: '1rem', padding: '0.5rem' }}>
        <Typography variant="h6">
          {currentView === 'month'
            ? `${t('workingTimeDetail.monthSummary')} ${startOfPeriod.format('MMMM YYYY')}`
            : `${t('workingTimeDetail.weekSummary')} ${startOfPeriod.format('DD MMM')} - ${endOfPeriod.format('DD MMM')}`}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalWorkedHours')}: {totalWorkedHours.toFixed(2)}{' '}
          {t('workingTimeDetail.hours')}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalBreakHours')}: {totalBreakHours.toFixed(2)}{' '}
          {t('workingTimeDetail.hours')}
        </Typography>
        <Typography variant="body2">
          {t('workingTimeDetail.totalVacationDays')}: {totalVacationDays}{' '}
          {t('workingTimeDetail.days')}
        </Typography>
      </div>
    );
  };

  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={dateLocale}>
      <Paper elevation={3} sx={{ mt: 4, p: 3 }}>
        <Typography variant="h4" gutterBottom>
          {id !== 'new'
            ? t('workingTimeDetail.editWorkingTime')
            : t('workingTimeDetail.addWorkingTime')}
        </Typography>

        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6}>
            <TextField
              select
              label={t('workingTimeDetail.employee')}
              name="employeeId"
              value={employeeId}
              onChange={handleEmployeeChange}
              fullWidth
              // ** Disable if ID is not "new" **
              disabled={id !== 'new'}
            >
              {employees?.map((emp) => (
                <MenuItem key={emp.id} value={emp.id}>
                  {emp.firstName} {emp.lastName}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>


        <Button
          variant="contained"
          color="primary"
          onClick={handleGeneratePDF}
          sx={{ mt: 2 }}
        >
          {t('workingTimeDetail.generatePDF')}
        </Button>

        <Grid container spacing={2}>
          <Grid item xs={8}>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              selectable
              onSelectSlot={handleSelectSlot}
              onSelectEvent={handleSelectEvent}
              dayPropGetter={dayStyleGetter}
              slotPropGetter={slotPropGetter}
              step={30}
              timeslots={2}
              eventPropGetter={eventStyleGetter}
              style={{ height: 600 }}
              views={['week', 'month']}
              // Control the view & navigation
              view={currentView}
              onView={(view) => setCurrentView(view)}
              onNavigate={(date) => {
                setCurrentDate(date);
                if (employeeId && employeeId !== 'new') {
                  loadEvents(employeeId, date, currentView);
                }
              }}
              formats={formats}
            />
          </Grid>
          <Grid item xs={4}>
            {renderSidePanel()}
          </Grid>
        </Grid>

        <Button variant="outlined" onClick={() => navigate('/working-times')} sx={{ mt: 2 }}>
          {t('common.cancel')}
        </Button>

        <SnackbarAlert
          open={snackbar.open}
          onClose={handleSnackbarClose}
          message={snackbar.message}
          severity={snackbar.severity}
        />

        {/* ------------------ MODAL for creating/editing an event ------------------ */}
        <Modal open={modalOpen} onClose={handleModalClose}>
          <div
            style={{
              padding: '2rem',
              backgroundColor: '#fff',
              margin: '5% auto',
              maxWidth: '600px',
              borderRadius: '8px',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
            }}
          >
            <Typography variant="h6" gutterBottom>
              {isEditing
                ? t('workingTimeDetail.editEvent')
                : selectedSlot && selectedSlot.slots && selectedSlot.slots.length > 1
                  ? t('workingTimeDetail.enterTimeForMultipleDays')
                  : selectedSlot
                    ? `${t('workingTimeDetail.enterTimeFor')} ${moment(selectedSlot.start).format('LLL')}`
                    : t('workingTimeDetail.enterTime')}
            </Typography>
            <Grid container spacing={2}>
              {/* Start Date */}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={t('workingTimeDetail.startDate')}
                  value={entryData.startDate || null}
                  onChange={(date) =>
                    setEntryData((prev) => ({ ...prev, startDate: date }))
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              {/* End Date */}
              <Grid item xs={12} sm={6}>
                <DatePicker
                  label={t('workingTimeDetail.endDate')}
                  value={entryData.endDate || null}
                  onChange={(date) =>
                    setEntryData((prev) => ({ ...prev, endDate: date }))
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
            </Grid>

            <TextField
              select
              label={t('workingTimeDetail.entryType')}
              name="type"
              value={entryData.type || ''}
              onChange={(e) =>
                setEntryData((prev) => ({ ...prev, type: e.target.value }))
              }
              fullWidth
              sx={{ mt: 2 }}
            >
              {entryTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>

            {/* Time pickers (hidden if 'vacation') */}
            {entryData.type !== 'vacation' && (
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('workingTimeDetail.startTime')}
                    type="time"
                    name="startTime"
                    value={entryData.startTime || ''}
                    onChange={(e) =>
                      setEntryData((prev) => ({ ...prev, startTime: e.target.value }))
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label={t('workingTimeDetail.endTime')}
                    type="time"
                    name="endTime"
                    value={entryData.endTime || ''}
                    onChange={(e) =>
                      setEntryData((prev) => ({ ...prev, endTime: e.target.value }))
                    }
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            )}

            {/* Checkbox for whether to create a single multi-day event or separate events */}
            {selectedSlot && selectedSlot.slots && selectedSlot.slots.length > 1 && !isEditing && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={entryData.createSingleEvent || false}
                    onChange={(e) =>
                      setEntryData((prev) => ({ ...prev, createSingleEvent: e.target.checked }))
                    }
                    name="createSingleEvent"
                    color="primary"
                  />
                }
                label={t('workingTimeDetail.createSingleEvent')}
                sx={{ mt: 2 }}
              />
            )}

            <Grid container spacing={2} sx={{ mt: 3 }}>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" onClick={handleSaveEntry} fullWidth>
                  {t('common.save')}
                </Button>
              </Grid>
              {isEditing && (
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={handleDeleteEvent}
                    startIcon={<DeleteIcon />}
                    fullWidth
                  >
                    {t('common.delete')}
                  </Button>
                </Grid>
              )}
            </Grid>
          </div>
        </Modal>

        {/* Floating Action Button for opening the modal */}
        <Fab
          color="primary"
          aria-label="add"
          onClick={() => {
            // If no slot selected and we're not editing, show an error
            if (!selectedSlot && !isEditing) {
              setSnackbar({
                open: true,
                message: t('workingTimeDetail.noDateSelected'),
                severity: 'error',
              });
              return;
            }
            setModalOpen(true);
          }}
          style={{ position: 'fixed', bottom: 16, right: 16 }}
          disabled={!selectedSlot && !isEditing}
        >
          <AddIcon />
        </Fab>
      </Paper>
    </LocalizationProvider>
  );
};

export default WorkingTimeDetailPage;
